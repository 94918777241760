/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=arimo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

/* @font-face {
  font-family: "arimo";
  src: url("/assets/fonts/Arimo/static/Arimo-Ligh.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */


@font-face {
  font-family: "arimo";
  src: url("/assets/fonts/Arimo/Arimo-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
}


@font-face {
  font-family: "antonio";
  src: url("/assets/fonts/antonio/static/Antonio-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* @font-face {
  font-family: "antonio";
  src: url("/assets/fonts/antonio/static/Antonio-Regiular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "antonio";
  src: url("/assets/fonts/antonio/static/Antonio-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
 */


:root {
  --font-arimo: "arimo", sans-serif;
  --font-mulish: "Mulish", sans-serif;
  /* --font-antonio: "antonio", sans-serif; */
  --font-montserrat: "Montserrat", sans-serif;
  /* --font-arimo: "arimo", sans-serif; */


  /* "arimo", sans-serif; */
}

html {
  scroll-behavior: smooth;
}

.c-pointer {
  cursor: pointer;
}

/* image popup */
img.mfp-img {
  /* box-shadow: 0 0 8px rgb(0 0 0 / 60%); */
  position: absolute;
  max-height: 392px;
  padding: 0 !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mfp-img-container .mfp-content {
  max-width: 400px !important;
}

.mfp-img-container .mfp-close {
  top: -110px;
  right: -24px;
}

.main-menu li.active>a,
.header-navigation .main-menu ul>li.has-children.active>a:after {
  color: #ff344f !important;
}